import { Reorder, useDragControls } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Button,
  Divider,
  HStack,
  MenuDivider,
  Stack,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { faGripDots } from '@fortawesome/pro-solid-svg-icons';
import ActionMenu, {
  CustomMenuItem,
  DeleteMenuItem,
  EditMenuItem
} from 'components/ActionMenu';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import ModalWrapper from 'components/ModalWrapper';
import api from 'utils/api';
import FieldTable from './FieldTable';
import StepForm from './StepForm';
import StepReminderForm from './StepReminderForm';
import MembersButton from 'components/MembersButton';

const StepListItem = ({
  step,
  workflow,
  onDrag,
  onFieldChangeStep,
  isOpen
}) => {
  const controls = useDragControls();
  const toast = useToast();

  const {
    isOpen: showUpdateStepModal,
    onOpen: openUpdateStepModal,
    onClose: closeUpdateStepModal
  } = useDisclosure();

  const {
    isOpen: showDeleteStepModal,
    onOpen: openDeleteStepModal,
    onClose: closeDeleteStepModal
  } = useDisclosure();

  const {
    isOpen: showSetReminderModal,
    onOpen: openSetReminderModal,
    onClose: closeSetReminderModal
  } = useDisclosure();

  const { t } = useTranslation();

  const deleteStepMutation = useMutation(
    () => api.delete(`/steps/${step.id}`),
    {
      onSuccess: () => {
        const steps = queryClient.getQueryData(['steps', workflow.id]);
        queryClient.setQueryData(
          ['steps', workflow.id],
          steps.filter(s => s.id !== step.id)
        );
        closeDeleteStepModal();
        toast({
          title: t('toast.delete_success', {
            entity: t('common.step')
          }),
          status: 'success'
        });
      },
      onError: () => {
        toast({
          title: t('toast.delete_error', {
            entity: t('common.step')
          }),
          status: 'error'
        });
      }
    }
  );

  const updateStepMutation = useMutation(
    payload => api.patch(`/steps/${step.id}`, payload),
    {
      onSuccess: ({ data: step }) => {
        toast({
          title: t('toast.update_success', {
            entity: t('common.step')
          }),
          status: 'success'
        });
        closeUpdateStepModal();
        closeSetReminderModal();
        const steps = queryClient.getQueryData(['steps', workflow.id]);
        queryClient.setQueryData(
          ['steps', workflow.id],
          steps.map(s => (s.id === step.id ? step : s))
        );
      },
      onError: () => {
        toast({
          title: t('toast.update_error', {
            entity: t('common.step')
          }),
          status: 'error'
        });
      }
    }
  );

  const handleOnClickDelete = async () => {
    await deleteStepMutation.mutateAsync(step.id);
  };

  const handleOnUpdateStep = async values => {
    await updateStepMutation.mutateAsync({ ...values, workflow: workflow.id });
  };

  const queryClient = useQueryClient();

  return (
    <Reorder.Item
      as={AccordionItem}
      value={step}
      dragListener={false}
      dragControls={controls}
      borderWidth={0}
      borderBottom="none"
    >
      <HStack spacing={4} py={4}>
        <Text
          cursor="grab"
          variant="light"
          onPointerDown={e => {
            onDrag();
            controls.start(e);
          }}
        >
          <FontAwesomeIcon icon={faGripDots} />
        </Text>
        <AccordionButton
          px={0}
          _hover={{
            bg: null
          }}
        >
          <HStack spacing={4} width="full">
            <Badge colorScheme={step ? step.color : null} userSelect="none">
              {step.name}
            </Badge>
            <Divider />
          </HStack>
          <AccordionIcon ml={2} />
        </AccordionButton>
        <ActionMenu>
          <CustomMenuItem
            label={t('workflow.set_reminder')}
            icon={faClock}
            onClick={openSetReminderModal}
          />
          <MenuDivider />
          <EditMenuItem onClick={openUpdateStepModal} />
          <DeleteMenuItem onClick={openDeleteStepModal} />
        </ActionMenu>
      </HStack>
      <AccordionPanel pb={8}>
        <Stack spacing={4}>
          {step.reminder_days && (
            <Button
              maxWidth="fit-content"
              variant="outline"
              size="sm"
              onClick={openSetReminderModal}
            >
              {t('common.reminder')}
            </Button>
          )}
          <HStack>
            <Text variant="muted">{t('common.panel')}</Text>
            <MembersButton
              max={3}
              members={step.panel}
              onSubmit={async members => {
                let panel = members.map(member => member.id);
                await handleOnUpdateStep({ panel });
              }}
              canEdit
            />
          </HStack>
          <FieldTable
            step={step}
            workflow={workflow}
            onFieldChangeStep={onFieldChangeStep}
            isOpen={isOpen}
          />
        </Stack>
      </AccordionPanel>
      <ModalWrapper
        title={t('common.step')}
        isOpen={showUpdateStepModal}
        onClose={closeUpdateStepModal}
      >
        <StepForm
          defaultValues={{
            name: step.name,
            color: step.color,
            hide_cards: step.hide_cards
          }}
          isOpen={showUpdateStepModal}
          onSubmit={handleOnUpdateStep}
        />
      </ModalWrapper>
      <ModalWrapper
        size="md"
        title={t('workflow.set_reminder')}
        isOpen={showSetReminderModal}
        onClose={closeSetReminderModal}
      >
        <Stack spacing={4}>
          <Text>{t('workflow.reminder_explainer')}</Text>
          <StepReminderForm
            defaultValues={{
              reminder_days: step.reminder_days
            }}
            isOpen={showSetReminderModal}
            onDelete={async () => {
              await handleOnUpdateStep({ reminder_days: null });
            }}
            onSubmit={async values => {
              await handleOnUpdateStep(values);
            }}
          />
        </Stack>
      </ModalWrapper>
      <ConfirmationModal
        deleteText={t(
          'confirmation.this_data_will_be_permanently_removed_and_cannot_be_restored',
          { data: t('common.step').toLowerCase() }
        )}
        isOpen={showDeleteStepModal}
        onClose={closeDeleteStepModal}
        onDelete={handleOnClickDelete}
      />
    </Reorder.Item>
  );
};

export default StepListItem;
