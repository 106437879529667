import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Flex, Heading, Stack, useToast } from '@chakra-ui/react';
import AnnouncementForm from './AnnouncementForm';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import CoverMedia from 'components/CoverMedia';
import Description from 'components/Description';
import {
  ActionMenu,
  EditMenuItem,
  DeleteMenuItem
} from 'components/ActionMenu';
import api from 'utils/api';
import ModalWrapper from 'components/ModalWrapper';

const AnnouncementDetailed = ({ announcement, canEdit, isOpen, onClose }) => {
  const [showDeleteAnnouncementModal, setShowDeleteAnnouncementModal] =
    useState(false);
  const [showUpdateAnnouncementModal, setShowUpdateAnnouncementModal] =
    useState(false);

  const toast = useToast();

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { isLoading, mutateAsync: deleteAnnouncementMutation } = useMutation(
    async () => {
      await api.delete(`/announcements/${announcement.id}`);
    },
    {
      onSuccess: () => {
        const announcements = queryClient.getQueryData('announcements');
        if (announcements) {
          const pages = announcements.pages.map(page => ({
            ...page,
            count: page.count - 1,
            results: page.results.filter(a => a.id !== announcement.id)
          }));
          queryClient.setQueryData('announcements', {
            ...announcements,
            pages
          });
        }
        const home = queryClient.getQueryData('home');
        if (home) {
          queryClient.setQueryData('home', {
            ...home,
            announcement:
              home.announcement.id === announcement.id
                ? null
                : home.announcement
          });
        }
        toggleShowDeleteAnnouncementModal();
        onClose();
        toast({
          title: t('toast.delete_success', {
            entity: t('common.announcement')
          }),
          status: 'success'
        });
      }
    }
  );

  const updateAnnouncementMutation = useMutation(
    payload => api.patch(`/announcements/${announcement.id}`, payload),
    {
      onSuccess: ({ data }) => {
        const announcements = queryClient.getQueryData('announcements');
        if (announcements) {
          const pages = announcements.pages.map(page => ({
            ...page,
            results: page.results.map(a => (a.id === data.id ? data : a))
          }));
          queryClient.setQueryData('announcements', {
            ...announcements,
            pages
          });
        }
        const home = queryClient.getQueryData('home');
        if (home) {
          queryClient.setQueryData('home', {
            ...home,
            announcement:
              home.announcement.id === data.id ? data : home.announcement
          });
        }
        toggleShowUpdateAnnouncementModal();
        onClose();
        toast({
          title: t('toast.update_success', {
            entity: t('common.announcement')
          }),
          status: 'success'
        });
      }
    }
  );

  const handleOnClickDelete = async () => {
    await deleteAnnouncementMutation();
  };

  const handleOnUpdateAnnouncement = async announcement => {
    const payload = new FormData();
    if (!announcement.image || announcement.image instanceof File) {
      payload.append('image', announcement.image ? announcement.image : '');
    }
    if (!announcement.video) {
      payload.append('video', '');
    }
    payload.append(
      'unsplash',
      announcement.unsplash ? announcement.unsplash : ''
    );
    payload.append('title', announcement.title);
    payload.append(
      'description',
      announcement.description ? announcement.description : ''
    );
    try {
      if (announcement.video instanceof File) {
        const { data } = await api.post('/s3/generate-presigned-url', {
          filename: announcement.video.name
        });
        await axios.put(data.url, announcement.video, {
          headers: { 'x-amz-acl': 'public-read' }
        });
        payload.append('video', data.key);
      }
    } catch (e) {
      console.log(e);
    }
    await updateAnnouncementMutation.mutateAsync(payload);
  };

  const toggleShowDeleteAnnouncementModal = () => {
    setShowDeleteAnnouncementModal(!showDeleteAnnouncementModal);
  };

  const toggleShowUpdateAnnouncementModal = () => {
    setShowUpdateAnnouncementModal(!showUpdateAnnouncementModal);
  };

  return (
    <>
      <ModalWrapper
        title={t('common.announcement')}
        isOpen={isOpen}
        onClose={onClose}
        blockScrollOnMount={false}
      >
        <Stack spacing={4} pb={4}>
          <CoverMedia object={announcement} height="200px" />
          <Flex alignItems="center" justifyContent="space-between">
            <Heading fontSize="lg">{announcement.title}</Heading>
            {canEdit && (
              <ActionMenu>
                <EditMenuItem onClick={toggleShowUpdateAnnouncementModal} />
                <DeleteMenuItem onClick={toggleShowDeleteAnnouncementModal} />
              </ActionMenu>
            )}
          </Flex>
          <Description>{announcement.description}</Description>
        </Stack>
      </ModalWrapper>
      <ConfirmationModal
        deleteText={t(
          'confirmation.all_information_in_this_card_will_be_permanently_removed_and_cannot_be_restored'
        )}
        isLoading={isLoading}
        isOpen={showDeleteAnnouncementModal}
        onClose={toggleShowDeleteAnnouncementModal}
        onDelete={handleOnClickDelete}
      />
      <ModalWrapper
        title={t('common.announcement')}
        isOpen={showUpdateAnnouncementModal}
        onClose={toggleShowUpdateAnnouncementModal}
      >
        <AnnouncementForm
          defaultValues={{
            ...announcement,
            description: announcement.description
          }}
          onSubmit={handleOnUpdateAnnouncement}
        />
      </ModalWrapper>
    </>
  );
};

export default AnnouncementDetailed;
