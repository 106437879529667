import { app } from '@microsoft/teams-js';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AnnouncementList from 'features/announcement/AnnouncementList';
import HomePage from 'features/home/HomePage';
import Auth from 'features/auth/Auth';
import AuthSilent from 'features/auth/AuthSilent';
import FeedPage from 'features/feed/FeedPage';
import ChannelList from 'features/channel/ChannelList';
import DashboardPage from 'features/dashboard/DashboardPage';
import { useAuth } from 'providers/AuthProvider';
import ProfileRoute from 'routes/ProfileRoute';
import HiveRoute from 'routes/HiveRoute';
import SearchPage from 'features/search/SearchPage';
import MemberDetailed from 'features/member/MemberDetailed';
import CollectionDetailed from 'features/collection/CollectionDetailed';
import SettingsPage from 'features/settings/SettingsPage';
import AdminPage from 'features/admin/AdminPage';
import AdminUsers from 'features/user/AdminUsers';
import AdminHints from 'features/hint/AdminHints';
import AdminHive from 'features/hive/AdminHive';
import ChannelDetailed from 'features/channel/ChannelDetailed';
import WorkflowList from 'features/workflow/WorkflowList';
import WorkflowDetailed from 'features/workflow/WorkflowDetailed';
import MyProjects from 'features/project/MyProjects';
import ProjectDetailed from 'features/project/ProjectDetailed';
import ProjectTemplateList from 'features/project/template/ProjectTemplateList';
import ProjectTemplateDetailed from 'features/project/template/ProjectTemplateDetailed';
import JoinPage from 'features/auth/JoinPage';
import SharedCollectionDetailed from 'features/collection/SharedCollectionDetailed';
import InvitedCollectionDetailed from 'features/collection/InvitedCollectionDetailed';
import NoMatch from 'components/NoMatch';
import Scrollable from 'components/Scrollable';
import { useTeams } from 'msteams-react-base-component';
import { CardProvider } from 'providers/CardProvider';
import LoginPage from 'features/auth/LoginPage';
import LoginRedirect from 'features/auth/LoginRedirect';
import HiveRegisterPage from 'features/hive/HiveRegisterPage';
import ReportDetailed from 'features/report/ReportDetailed';
import HiveRedirect from 'routes/HiveRedirect';
import api from 'utils/api';
import TeamsLogin from 'features/auth/TeamsLogin';
import CreateHivePage from 'features/hive/CreateHivePage';
import CollectionReportDetailed from 'features/collection/CollectionReportDetailed';
import SurveyDetailed from 'features/survey/SurveyDetailed';
import Saml from 'features/auth/Saml';
import BoardOrderPage from 'features/board/BoardOrderPage';
import ManagePage from 'features/member/ManagePage';
import SpaAuth from 'features/auth/SpaAuth';
import FolderPage from 'features/folders/FolderPage';
import FolderArchivedPage from 'features/folders/FolderArchivedPage';
import FolderUncategorizedPage from 'features/folders/FolderUncategorizedPage';
import SharedSurveyDetailed from 'features/survey/SharedSurveyDetailed';
import SurveyPreview from 'features/survey/SurveyPreview';
import BoardDetailed from 'features/board/BoardDetailed';

const AppRouter = () => {
  const [{ inTeams }] = useTeams();
  const { authenticated } = useAuth();
  const navigate = useNavigate();
  const { refetch: fetchMe } = useQuery(
    'me',
    async () => {
      const { data: user } = await api.get('/users/me');
      return user;
    },
    {
      enabled: false,
      onError: () => {}
    }
  );

  useEffect(() => {
    if (inTeams) {
      async function initialize() {
        try {
          await app.initialize();
          app.notifySuccess();
          app.notifyAppLoaded();
        } catch (e) {
          console.log(e);
        }
      }
      initialize();
    }
  }, [inTeams]);

  useEffect(() => {
    if (authenticated) {
      fetchMe();
    } else if (inTeams) {
      navigate('/msteams');
    }
  }, [authenticated, inTeams, fetchMe, navigate]);

  return (
    <Routes>
      <Route path="/hives/register" element={<HiveRegisterPage />} />
      <Route path="/hives/create" element={<CreateHivePage />} />
      <Route path="/login/:slug" element={<LoginRedirect />} />
      <Route
        path="/join"
        element={
          <Scrollable>
            <JoinPage />
          </Scrollable>
        }
      />
      <Route path=":slug" element={<HiveRoute />}>
        <Route
          path=""
          element={
            <ProfileRoute>
              <HomePage />
            </ProfileRoute>
          }
        />
        <Route
          path="admin"
          element={
            <ProfileRoute>
              <AdminPage />
            </ProfileRoute>
          }
        >
          <Route path="users" element={<AdminUsers />} />
          <Route path="hints" element={<AdminHints />} />
          <Route path="hive" element={<AdminHive />} />
        </Route>
        <Route
          path="announcements"
          element={
            <ProfileRoute>
              <AnnouncementList />
            </ProfileRoute>
          }
        />
        <Route
          path="settings"
          element={
            <ProfileRoute>
              <SettingsPage />
            </ProfileRoute>
          }
        />
        <Route
          path="feed"
          element={
            <ProfileRoute>
              <FeedPage />
            </ProfileRoute>
          }
        />
        <Route
          path="board-order"
          element={
            <ProfileRoute>
              <BoardOrderPage />
            </ProfileRoute>
          }
        />
        <Route
          path="board/:boardId"
          element={
            <ProfileRoute>
              <BoardDetailed />
            </ProfileRoute>
          }
        />
        <Route
          path="channels"
          element={
            <ProfileRoute>
              <ChannelList />
            </ProfileRoute>
          }
        />
        <Route
          path="channel/:channelId"
          element={
            <ProfileRoute>
              <ChannelDetailed />
            </ProfileRoute>
          }
        />
        <Route
          path="dashboard"
          element={
            <ProfileRoute>
              <DashboardPage />
            </ProfileRoute>
          }
        />
        <Route
          path="folder/:folderId"
          element={
            <ProfileRoute>
              <FolderPage />
            </ProfileRoute>
          }
        />
        <Route
          path="collections"
          element={
            <ProfileRoute>
              <ManagePage />
            </ProfileRoute>
          }
        />
        <Route
          path="collection/:collectionId"
          element={
            <ProfileRoute escapeContainerScroll>
              <CollectionDetailed />
            </ProfileRoute>
          }
        />
        <Route
          path="survey/:surveyId/preview"
          element={
            <ProfileRoute showChildOnly>
              <SurveyPreview />
            </ProfileRoute>
          }
        />
        <Route
          path="survey/:surveyId"
          element={
            <ProfileRoute escapeContainerScroll>
              <SurveyDetailed />
            </ProfileRoute>
          }
        />
        <Route
          path="projects"
          element={
            <ProfileRoute>
              <MyProjects />
            </ProfileRoute>
          }
        />
        <Route
          path="project/:projectId"
          element={
            <ProfileRoute>
              <ProjectDetailed />
            </ProfileRoute>
          }
        />
        <Route
          path="project/templates"
          element={
            <ProfileRoute>
              <ProjectTemplateList />
            </ProfileRoute>
          }
        />
        <Route
          path="project/template/:projectTemplateId"
          element={
            <ProfileRoute>
              <ProjectTemplateDetailed />
            </ProfileRoute>
          }
        />
        <Route
          path="search"
          element={
            <ProfileRoute>
              <SearchPage />
            </ProfileRoute>
          }
        />
        <Route
          path="settings"
          element={
            <ProfileRoute>
              <SettingsPage />
            </ProfileRoute>
          }
        />
        <Route
          path="member/:memberId"
          element={
            <ProfileRoute>
              <MemberDetailed />
            </ProfileRoute>
          }
        />
        <Route
          path="archived"
          element={
            <ProfileRoute>
              <FolderArchivedPage />
            </ProfileRoute>
          }
        />
        <Route
          path="uncategorized"
          element={
            <ProfileRoute>
              <FolderUncategorizedPage />
            </ProfileRoute>
          }
        />
        <Route
          path="workflows"
          element={
            <ProfileRoute>
              <WorkflowList />
            </ProfileRoute>
          }
        />
        <Route
          path="workflow/:workflowId"
          element={
            <ProfileRoute>
              <WorkflowDetailed />
            </ProfileRoute>
          }
        />
        <Route
          path="reports/:cardId"
          element={
            <ProfileRoute showChildOnly>
              <ReportDetailed />
            </ProfileRoute>
          }
        />
        <Route
          path="collection-reports/:reportId"
          element={
            <ProfileRoute showChildOnly>
              <CollectionReportDetailed />
            </ProfileRoute>
          }
        />
        <Route
          path="invited/collection/:collectionId"
          element={
            <CardProvider>
              <InvitedCollectionDetailed />
            </CardProvider>
          }
        />
        <Route
          path="shared/collection/:code"
          element={
            <CardProvider>
              <SharedCollectionDetailed />
            </CardProvider>
          }
        />
        <Route
          path="shared/survey/:code"
          element={
            <Scrollable>
              <SharedSurveyDetailed />
            </Scrollable>
          }
        />
        <Route
          path="preview/survey/:code"
          element={
            <Scrollable>
              <SurveyPreview />
            </Scrollable>
          }
        />
      </Route>
      <Route
        exact
        path="/msteams"
        element={
          <Scrollable>
            <TeamsLogin />
          </Scrollable>
        }
      />
      <Route
        path="/"
        element={
          <Scrollable>
            <LoginPage />
          </Scrollable>
        }
      />
      <Route path="/saml" element={<Saml />} />
      <Route path="/auth/silent" element={<AuthSilent />} />
      <Route path="/auth/:provider" element={<Auth />} />
      <Route path="/spa/auth/:provider" element={<SpaAuth />} />
      <Route
        path="/collection/:id"
        element={<HiveRedirect endpoint="/collections/:id/hive" />}
      />
      <Route
        path="/shared/collection/:id"
        element={<HiveRedirect endpoint="/shared/collections/:id/hive" />}
      />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

export default AppRouter;
