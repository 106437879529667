import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  AspectRatio,
  Badge,
  Button,
  Container,
  Flex,
  HStack,
  Heading,
  SimpleGrid,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faLink } from '@fortawesome/pro-solid-svg-icons';
import LoadingWrapper from 'components/LoadingWrapper';
import SurveyBuildTab from './SurveyBuildTab';
import SurveyForm from './SurveyForm';
import ActionMenu, {
  CustomMenuItem,
  DeleteMenuItem,
  EditMenuItem
} from 'components/ActionMenu';
import ModalWrapper from 'components/ModalWrapper';
import CoverMedia from 'components/CoverMedia';
import TitleDescription from 'components/TitleDescription';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import ChangeFolderModal from 'features/folders/ChangeFolderModal';
import FolderAssociationButton from 'features/folders/FolderAssociationButton';
import { useUi } from 'providers/UiProvider';
import api from 'utils/api';
import ShareSurvey from './ShareSurvey';
import SurveyResultTab from './SurveyResultTab';

const SurveyDetailed = () => {
  const { slug } = useParams();
  const [tabIndex, setTabIndex] = useState(0);
  const { scrollRef } = useUi();
  const [filter, setFilter] = useState([]);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const toast = useToast();

  const { t } = useTranslation();
  const { surveyId } = useParams();

  const {
    isOpen: showDeleteSurveModal,
    onOpen: openDeleteSurveyModal,
    onClose: closeDeleteSurveyModal
  } = useDisclosure();

  const {
    isOpen: showUpdateSurveyModal,
    onOpen: openUpdateSurveyModal,
    onClose: closeUpdateSurveyModal
  } = useDisclosure();

  const {
    isOpen: showShareSurveyModal,
    onOpen: openShareSurveyModal,
    onClose: closeShareSurveyModal
  } = useDisclosure();

  const {
    isOpen: showChangeFolderModal,
    onOpen: openChangeFolderModal,
    onClose: closeChangeFolderModal
  } = useDisclosure();

  const {
    status: surveyStatus,
    data: survey,
    isSuccess: surveyIsSuccess,
    refetch
  } = useQuery(['survey', surveyId], async () => {
    const { data } = await api.get(`/surveys/${surveyId}`);
    return data;
  });

  const {
    status: fieldsStatus,
    data: fields,
    isSuccess: fieldsIsSuccess
  } = useQuery(['survey', surveyId, 'fields', filter], async () => {
    const options = filter.map(field => field.options.map(o => o.id)).flat();
    const params = new URLSearchParams({
      survey: surveyId,
      options: options.join(',')
    });
    const { data } = await api.get(`/surveys/fields?${params.toString()}`);
    return data;
  });

  const { isLoading, mutateAsync: deleteSurveyMutation } = useMutation(
    () => api.delete(`/surveys/${surveyId}`),
    {
      onSuccess: () => {
        navigate('/');
        toast({
          title: t('toast.delete_success', {
            entity: t('common.survey')
          }),
          status: 'success'
        });
        closeDeleteSurveyModal();
      }
    }
  );

  useEffect(() => {
    queryClient.invalidateQueries(['survey', surveyId, 'fields']);
  }, [queryClient, surveyId, filter]);

  const updateSurveyMutation = useMutation(
    payload =>
      api.patch(`/surveys/${surveyId}`, payload, {
        timeout: 0
      }),
    {
      onSuccess: ({ data }) => {
        queryClient.setQueryData(['survey', surveyId], data);
        toast({
          title: t('toast.update_success', {
            entity: t('common.survey')
          }),
          status: 'success'
        });
        closeUpdateSurveyModal();
      }
    }
  );

  const handleOnChangeFolder = async ({ folder }) => {
    await updateSurveyMutation.mutateAsync({
      folder: folder ? folder.id : null,
      hive__slug: slug
    });
    closeChangeFolderModal();
    refetch();
  };

  /* Refresh the results if the user navigates to the results tab */
  useEffect(() => {
    if (tabIndex === 1) {
      queryClient.invalidateQueries(['survey', surveyId]);
    }
  }, [tabIndex, queryClient, surveyId]);

  const handleOnClickDelete = async () => {
    await deleteSurveyMutation();
  };

  const handleOnUpdateSurvey = async s => {
    const payload = new FormData();
    if (!s.image || s.image instanceof File) {
      payload.append('image', s.image ? s.image : '');
    }
    if (!s.video) {
      payload.append('video', '');
    }
    payload.append('unsplash', s.unsplash ? s.unsplash : '');
    payload.append('title', s.title);
    payload.append('description', s.description ? s.description : '');
    try {
      if (s.video instanceof File) {
        const { data } = await api.post('/s3/generate-presigned-url', {
          filename: s.video.name
        });
        await axios.put(data.url, s.video, {
          headers: { 'x-amz-acl': 'public-read' }
        });
        payload.append('video', data.key);
      }
    } catch (e) {
      console.log(e);
    }
    await updateSurveyMutation.mutateAsync(payload);
  };

  return (
    <>
      <LoadingWrapper
        statuses={[surveyStatus]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.fields').toLowerCase()
          }),
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.survey').toLowerCase()
          })
        ]}
      >
        {surveyIsSuccess && (
          <Flex ref={scrollRef} overflowY="scroll" height="calc(100vh - 70px)">
            <Container maxW="container.lg" marginY={8}>
              <Stack spacing={8}>
                <HStack spacing={4}>
                  <Heading fontSize="3xl">{t('common.survey')}</Heading>
                  <Spacer />
                  <Button variant="outline" onClick={openShareSurveyModal}>
                    <HStack>
                      <FontAwesomeIcon icon={faLink} />
                      <Text>{`${t('common.share')} / ${t(
                        'common.invite'
                      )}`}</Text>
                    </HStack>
                  </Button>
                  <ActionMenu>
                    <EditMenuItem onClick={openUpdateSurveyModal} />
                    <DeleteMenuItem onClick={openDeleteSurveyModal} />
                    <CustomMenuItem
                      icon={faFolder}
                      label={
                        survey?.folder
                          ? t('collection.change_folder')
                          : t('collection.add_to_folder')
                      }
                      onClick={openChangeFolderModal}
                    />
                  </ActionMenu>
                </HStack>
                <SimpleGrid columns={[1, null, 2]} spacing={8}>
                  <AspectRatio ratio={16 / 9}>
                    <CoverMedia object={survey} />
                  </AspectRatio>
                  <Stack
                    spacing={8}
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Stack spacing={4}>
                      <FolderAssociationButton
                        folder={survey?.folder}
                        onClickMoveToFolder={openChangeFolderModal}
                      />
                      <TitleDescription
                        title={survey.title}
                        description={survey.description}
                        noOfLines={5}
                      />
                    </Stack>
                    <Button
                      as={Link}
                      to={`/${slug}/preview/survey/${survey.preview_code}`}
                      target="_blank"
                      variant="outline"
                      isDisabled={survey.fields.length === 0}
                      onClick={e =>
                        survey.fields.length === 0 && e.preventDefault()
                      }
                    >
                      {t('common.preview_verb')}
                    </Button>
                  </Stack>
                </SimpleGrid>
                <Tabs
                  size="sm"
                  variant="fresh"
                  index={tabIndex}
                  onChange={tabIndex => {
                    setTabIndex(tabIndex);
                  }}
                  isLazy
                >
                  <SimpleGrid
                    templateColumns={['1fr', null, '1fr 200px']}
                    spacing={4}
                    overflowX="auto"
                  >
                    <TabList>
                      <Tab>{t('survey.build')}</Tab>
                      <Tab>
                        <HStack>
                          <Text>{t('survey.results')}</Text>
                          <Badge
                            size="sm"
                            variant={tabIndex === 1 ? 'solid' : 'subtle'}
                          >
                            {survey.metrics?.responses}
                          </Badge>
                        </HStack>
                      </Tab>
                    </TabList>
                  </SimpleGrid>
                  <TabPanels>
                    <TabPanel>
                      <SurveyBuildTab
                        surveyId={surveyId}
                        fields={fields ? fields : []}
                        filter={filter}
                        survey={survey}
                      />
                    </TabPanel>
                    <TabPanel>
                      <SurveyResultTab
                        surveyId={surveyId}
                        fields={fields}
                        fieldsStatus={fieldsStatus}
                        fieldsIsSuccess={fieldsIsSuccess}
                        survey={survey}
                        filter={filter}
                        setFilter={setFilter}
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Stack>
            </Container>
            <ModalWrapper
              title={t('common.share_survey')}
              isOpen={showShareSurveyModal}
              onClose={closeShareSurveyModal}
              size="xl"
            >
              <ShareSurvey surveyId={surveyId} survey={survey} />
            </ModalWrapper>
            <ModalWrapper
              title={t('common.survey')}
              isOpen={showUpdateSurveyModal}
              onClose={closeUpdateSurveyModal}
            >
              <SurveyForm
                defaultValues={{
                  ...survey,
                  description: survey.description
                }}
                isOpen={showUpdateSurveyModal}
                onSubmit={handleOnUpdateSurvey}
              />
            </ModalWrapper>
            <ConfirmationModal
              deleteText={t(
                'confirmation.all_information_in_this_card_will_be_permanently_removed_and_cannot_be_restored'
              )}
              isLoading={isLoading}
              isOpen={showDeleteSurveModal}
              onClose={closeDeleteSurveyModal}
              onDelete={handleOnClickDelete}
            />
            <ChangeFolderModal
              folder={survey?.folder}
              isOpen={showChangeFolderModal}
              onClose={closeChangeFolderModal}
              onChange={handleOnChangeFolder}
            />
          </Flex>
        )}
      </LoadingWrapper>
    </>
  );
};

export default SurveyDetailed;
