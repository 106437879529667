import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import WorkflowForm from './WorkflowForm';
import WorkflowListItem from './WorkflowListItem';
import BackButton from 'components/BackButton';
import BoxContainer from 'components/BoxContainer';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import api from 'utils/api';

const WorkflowList = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug]);
  const toast = useToast();

  const { t } = useTranslation();

  const {
    isOpen: showCreateWorkflowModal,
    onOpen: openCreateWorkflowModal,
    onClose: closeCreateWorkflowModal
  } = useDisclosure();

  const {
    status,
    data: workflows,
    isSuccess
  } = useQuery(['workflows', slug], async () => {
    const params = new URLSearchParams({
      hive__slug: slug
    });
    const { data } = await api.get(`/workflows?${params.toString()}`);
    return data;
  });

  const createWorkflowMutation = useMutation(
    workflow => api.post('/workflows', workflow),
    {
      onSuccess: ({ data: workflow }) => {
        closeCreateWorkflowModal();
        navigate(`/${slug}/workflow/${workflow.id}`);
        toast({
          title: t('toast.create_success', {
            entity: t('common.workflow')
          }),
          status: 'success'
        });
      },
      onError: () => {
        toast({
          title: t('toast.create_error', {
            entity: t('common.workflow')
          }),
          status: 'error'
        });
      }
    }
  );

  const handleOnCreateWorkflow = async workflow => {
    await createWorkflowMutation.mutateAsync({
      ...workflow,
      hive: hive.id,
      type: workflow.type.id
    });
  };

  return (
    <Container maxW="container.lg" marginY={8}>
      <Stack spacing={4}>
        <Flex alignItems="center" justifyContent="space-between">
          <Heading fontSize="2xl">{t('common.workflows')}</Heading>
          <Button colorScheme="teal" onClick={openCreateWorkflowModal}>
            <HStack>
              <FontAwesomeIcon icon={faPlus} />
              <Text>{t('common.workflow')}</Text>
            </HStack>
          </Button>
        </Flex>
        <BackButton />
        <LoadingWrapper
          statuses={[status]}
          errorMessages={[
            t('common.could_not_fetch_data_please_try_again_later', {
              data: t('common.workflows').toLowerCase()
            })
          ]}
        >
          {isSuccess ? (
            <SimpleGrid spacing={4} columns={[1, 2, 3, 4]}>
              {workflows.map(workflow => (
                <BoxContainer
                  key={workflow.id}
                  as={Link}
                  to={`/${slug}/workflow/${workflow.id}`}
                >
                  <WorkflowListItem workflow={workflow} />
                </BoxContainer>
              ))}
            </SimpleGrid>
          ) : null}
        </LoadingWrapper>
      </Stack>
      <ModalWrapper
        title={t('workflow.create_workflow')}
        size="full"
        isOpen={showCreateWorkflowModal}
        onClose={closeCreateWorkflowModal}
      >
        <WorkflowForm
          isOpen={showCreateWorkflowModal}
          onSubmit={handleOnCreateWorkflow}
        />
      </ModalWrapper>
    </Container>
  );
};

export default WorkflowList;
