export const findMedia = item => {
  if (item?.file) {
    return item.file;
  }
  if (item?.video) {
    return item.video;
  }
  if (item?.image || isImage(item)) {
    return item.image;
  }
  if (item?.unsplash) {
    return item.unsplash;
  }
  return null;
};

export const isImage = url => {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/i.test(url);
};

export const isVideo = url => {
  return /\.(mp4|ogg|webm)$/i.test(url);
};

export const getMediaType = url => {
  if (url && typeof url === 'string') {
    if (isVideo(url)) {
      return 'video';
    }
    if (isImage(url) || url?.startsWith('https://images.unsplash.com')) {
      return 'image';
    }
  }
  return null;
};
