import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Divider,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Stack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Button,
  Switch,
  FormControl,
  useToast
} from '@chakra-ui/react';
import HiveAccessForm from './HiveAccessForm';
import BoxContainer from 'components/BoxContainer';
import LoadingWrapper from 'components/LoadingWrapper';
import DomainList from 'features/domain/DomainList';
import api from 'utils/api';
import SpinnerSwitch from 'components/SpinnerSwitch';
import IconTooltip from 'components/tooltips/IconTooltip';
import ConsentForm from './ConsentForm';
import { useForm } from 'react-hook-form';

const AdminHive = () => {
  const { slug } = useParams();
  const { t } = useTranslation();
  const toast = useToast();

  const queryClient = useQueryClient();

  const {
    status,
    data: hive,
    isSuccess
  } = useQuery(['hive', slug], async () => {
    const { data } = await api.get(`/hives/${slug}`);
    return data;
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { isSubmitting, isDirty }
  } = useForm({
    mode: 'onChange',
    defaultValues: hive
      ? {
          force_group_selection: hive.force_group_selection,
          use_share: hive.use_share,
          use_feed: hive.use_feed,
          use_downvotes: hive.use_downvotes,
          sanitize_text: hive.sanitize_text
        }
      : {}
  });

  const {
    register: openRegister,
    reset: openReset,
    handleSubmit: handleSubmitOpen,
    formState: { isSubmitting: openIsSubmitting, isDirty: openIsDirty }
  } = useForm({
    mode: 'onChange',
    defaultValues: hive
      ? {
          open: hive.open
        }
      : {}
  });

  const updateHiveMutation = useMutation(
    payload => api.patch(`/hives/${slug}`, payload),
    {
      onSuccess: ({ data: hive }, variables) => {
        queryClient.setQueryData(['hive', slug], hive);
        toast({
          title: t('toast.update_success', {
            entity: t('common.hive')
          }),
          status: 'success'
        });
        if (variables.open !== undefined) {
          openReset({ open: hive.open });
        } else {
          reset({
            force_group_selection: hive.force_group_selection,
            use_share: hive.use_share,
            use_feed: hive.use_feed,
            use_downvotes: hive.use_downvotes,
            sanitize_text: hive.sanitize_text
          });
        }
      },
      onError: () => {
        toast({
          title: t('toast.update_error', {
            entity: t('common.hive')
          }),
          status: 'error'
        });
      }
    }
  );

  const handleOnUpdateHive = async payload => {
    await updateHiveMutation.mutateAsync(payload);
  };

  return (
    <LoadingWrapper
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.hive').toLowerCase()
        })
      ]}
    >
      {isSuccess ? (
        <Tabs size="sm" variant="fresh" isLazy>
          <TabList>
            {/* <Tab>{t('common.branding')}</Tab> */}
            <Tab>{t('common.access')}</Tab>
          </TabList>
          <TabPanels>
            {/* <TabPanel></TabPanel> */}
            <TabPanel>
              <SimpleGrid columns={[1, null, 2]} spacing={[4, null, 8]}>
                <BoxContainer alignSelf="flex-start">
                  <Stack spacing={8}>
                    <form
                      id="main-hive-form"
                      onSubmit={handleSubmit(handleOnUpdateHive)}
                    >
                      <Stack spacing={8}>
                        <Stack spacing={4}>
                          <Heading fontSize="lg">
                            {t('common.settings')}
                          </Heading>
                          <FormControl>
                            <Flex
                              width="full"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <HStack>
                                <Text>{t('admin.force_group_selection')}</Text>
                                <IconTooltip
                                  label={t(
                                    'admin.force_group_selection_tooltip'
                                  )}
                                  alignSelf="center"
                                  color="gray"
                                />
                              </HStack>
                              <Switch
                                id="force_group_selection"
                                {...register('force_group_selection')}
                              />
                            </Flex>
                          </FormControl>
                          <FormControl>
                            <Flex
                              width="full"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Text>{t('admin.use_share')}</Text>
                              <Switch
                                id="use_share"
                                {...register('use_share')}
                              />
                            </Flex>
                          </FormControl>
                          <FormControl>
                            <Flex
                              width="full"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Text>{t('admin.use_feed')}</Text>
                              <Switch id="use_feed" {...register('use_feed')} />
                            </Flex>
                          </FormControl>
                          <FormControl>
                            <Flex
                              width="full"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Text>{t('admin.use_downvotes')}</Text>
                              <Switch
                                id="use_downvotes"
                                {...register('use_downvotes')}
                              />
                            </Flex>
                          </FormControl>
                          <FormControl>
                            <Flex
                              width="full"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Text>{t('admin.language_filter')}</Text>
                              <Switch
                                id="sanitize_text"
                                {...register('sanitize_text')}
                              />
                            </Flex>
                          </FormControl>
                        </Stack>
                        <Button
                          isDisabled={!isDirty}
                          isLoading={isSubmitting}
                          colorScheme="teal"
                          type="submit"
                          form="main-hive-form"
                          width="fit-content"
                          alignSelf="flex-end"
                        >
                          {t('button.save')}
                        </Button>
                      </Stack>
                    </form>
                    <Divider />
                    <Stack spacing={4}>
                      <Heading fontSize="lg">
                        {t('common.sign_in_options')}
                      </Heading>
                      <HiveAccessForm
                        defaultValues={{
                          magic_link: hive.magic_link,
                          continue_with_facebook: hive.continue_with_facebook,
                          continue_with_google: hive.continue_with_google,
                          continue_with_linkedin: hive.continue_with_linkedin,
                          continue_with_microsoft: hive.continue_with_microsoft,
                          continue_with_slack: hive.continue_with_slack
                        }}
                        onSubmit={handleOnUpdateHive}
                      />
                    </Stack>
                  </Stack>
                </BoxContainer>
                <BoxContainer alignSelf="flex-start">
                  <form
                    id="open-hive-form"
                    onSubmit={handleSubmitOpen(handleOnUpdateHive)}
                  >
                    <Stack spacing={8}>
                      <Stack spacing={4}>
                        <Heading fontSize="lg">{t('common.open_hive')}</Heading>
                        <Stack spacing={8}>
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <FormControl>
                              <Flex
                                width="full"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Text>
                                  {t('invite.anyone_can_join_this_hive')}
                                </Text>
                                <Switch id="open" {...openRegister('open')} />
                              </Flex>
                            </FormControl>
                          </Flex>
                          <Button
                            isDisabled={!openIsDirty}
                            isLoading={openIsSubmitting}
                            colorScheme="teal"
                            type="submit"
                            form="open-hive-form"
                            width="fit-content"
                            alignSelf="flex-end"
                          >
                            {t('button.save')}
                          </Button>
                        </Stack>
                        <Divider />
                      </Stack>
                      <Stack spacing={4}>
                        <Heading fontSize="lg">
                          {t('common.allowed_domains')}
                        </Heading>
                        <DomainList />
                      </Stack>
                    </Stack>
                  </form>
                </BoxContainer>

                <BoxContainer alignSelf="flex-start">
                  <Stack spacing={4}>
                    <Stack spacing={4}>
                      <Heading fontSize="lg">{t('common.consent')}</Heading>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Text>{t('admin.require_consent')}</Text>
                        <SpinnerSwitch
                          isChecked={hive.require_consent}
                          optionKey="require_consent"
                          onChange={async () => {
                            await handleOnUpdateHive({
                              require_consent: !hive.require_consent
                            });
                          }}
                        />
                      </Flex>
                    </Stack>
                    <ConsentForm
                      defaultValues={{ consent_text: hive.consent_text }}
                      onSubmit={handleOnUpdateHive}
                    />
                  </Stack>
                </BoxContainer>
              </SimpleGrid>
            </TabPanel>
          </TabPanels>
        </Tabs>
      ) : null}
    </LoadingWrapper>
  );
};

export default AdminHive;
