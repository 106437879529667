import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Button,
  Flex,
  FormControl,
  Stack,
  Text,
  useToast
} from '@chakra-ui/react';
import { CreatableSelect } from 'chakra-react-select';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import api from 'utils/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

const ChangeFolderModal = ({
  folder,
  isOpen,
  onClose,
  onChange,
  isClearable = true,
  isRequired = false
}) => {
  const { slug } = useParams();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { data: hive } = useQuery(['hive', slug]);

  const {
    status,
    data: folders,
    isSuccess,
    refetch
  } = useQuery(
    ['folders'],
    async () => {
      const { data } = await api.get(`/folders?hive__slug=${slug}`, {
        timeout: 30000
      });
      return data;
    },
    {
      enabled: false
    }
  );

  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { isSubmitting, isValid }
  } = useForm({
    mode: 'onChange',
    folder
  });

  const createFolderMutation = useMutation(
    folder => api.post('/folders', folder),
    {
      onSuccess: ({ data: createdFolder }) => {
        toast({
          title: t('toast.create_success', {
            entity: t('common.folder')
          }),
          status: 'success'
        });
        queryClient.invalidateQueries(['folders', 'parent']);
        reset({ folder: createdFolder });
        refetch();
      },
      onError: () => {
        toast({
          title: t('toast.create_error', {
            entity: t('common.folder')
          }),
          status: 'error'
        });
        reset();
        refetch();
      }
    }
  );

  const handleOnCreateFolder = async folder => {
    reset({ folder: { ...folder, isNew: false } });
    await createFolderMutation.mutateAsync({
      name: folder.name,
      parent: null,
      hive: hive.id
    });
  };

  const handleOnAddOption = inputValue => {
    const newOption = { id: inputValue, name: inputValue, isNew: true };
    handleOnCreateFolder(newOption);
    return newOption;
  };

  const traverseFolders = folder => {
    let folders = [];
    if (folder.parent) {
      folders = [...traverseFolders(folder.parent), folder];
    } else {
      folders = [folder];
    }
    return folders;
  };

  const sortFolders = folders => {
    return folders.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return a.name.length - b.name.length;
    });
  };

  const formatBreadcrumbs = folders => {
    const foldersWithBreadcrumbLabels = folders
      ? folders.map(folder => {
          const hierarchy = traverseFolders(folder);
          const name = hierarchy.map(f => f.name).join(' > ');
          return { id: folder?.id, name };
        })
      : [];
    const sortedFolders = sortFolders(foldersWithBreadcrumbLabels);
    return sortedFolders;
  };

  useEffect(() => {
    if (!isOpen) {
      reset(folder);
    }
  }, [isOpen, folder, reset]);

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen, refetch]);

  const getNewOptionData = (inputValue, optionLabel) => ({
    id: inputValue,
    name: optionLabel,
    isNew: true,
    disabled: inputValue.length < 1
  });

  const options = formatBreadcrumbs(folders);
  const values = watch();

  return (
    <ModalWrapper
      title={t('common.folder')}
      isOpen={isOpen}
      onClose={onClose}
      pb={8}
    >
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.folders').toLowerCase()
          })
        ]}
      >
        {isSuccess && (
          <form onSubmit={handleSubmit(onChange)}>
            <Stack spacing={4}>
              <FormControl id="name">
                <Controller
                  fontSize="sm"
                  name="folder"
                  control={control}
                  rules={{ required: isRequired }}
                  render={({ field }) => (
                    <CreatableSelect
                      {...field}
                      onCreateOption={handleOnAddOption}
                      defaultValue={options?.find(f => f?.id === folder?.id)}
                      options={options}
                      isValidNewOption={value =>
                        !options.some(
                          o => o.name.toLowerCase() === value.toLowerCase()
                        )
                      }
                      createOptionPosition="first"
                      placeholder={t('placeholder.type_to_search_or_create')}
                      getOptionLabel={folder => folder?.name}
                      getOptionValue={folder => folder?.id}
                      getNewOptionData={getNewOptionData}
                      isClearable={isClearable}
                      useBasicStyles
                      formatCreateLabel={inputValue =>
                        inputValue.length > 0
                          ? t('folder.create_new_folder_named_value', {
                              value: inputValue
                            })
                          : t('placeholder.type_to_create_new_object', {
                              object: t('common.folder').toLowerCase()
                            })
                      }
                      isOptionDisabled={option => option.disabled}
                      create
                      formatOptionLabel={({ name, isNew = false }) => {
                        if (isNew) {
                          return (
                            <>
                              <FontAwesomeIcon icon={faPlus} />
                              <Text
                                ml={2}
                                fontStyle={values?.length > 0 ? null : 'italic'}
                                as="span"
                              >
                                {name}
                              </Text>
                            </>
                          );
                        }
                        const parts =
                          typeof name === 'string' ? name.split(' > ') : [];
                        return (
                          <div style={{ padding: '0.3rem 0' }}>
                            <p style={{ margin: '0.1rem 0' }}>
                              {parts[parts.length - 1]}
                            </p>
                            {parts.map((part, index) => (
                              <React.Fragment key={index}>
                                {index > 0 && (
                                  <FontAwesomeIcon
                                    icon={faChevronRight}
                                    style={{
                                      margin: '0 0.5rem',
                                      fontSize: '0.6rem',
                                      opacity: 0.6
                                    }}
                                  />
                                )}
                                <Text opacity="0.6" fontSize="xs" as="span">
                                  {part}
                                </Text>
                              </React.Fragment>
                            ))}
                          </div>
                        );
                      }}
                    />
                  )}
                />
              </FormControl>
              <Flex justifyContent="flex-end">
                <Button
                  type="submit"
                  colorScheme="teal"
                  isDisabled={!isValid}
                  isLoading={isSubmitting}
                >
                  {t('button.save')}
                </Button>
              </Flex>
            </Stack>
          </form>
        )}
      </LoadingWrapper>
    </ModalWrapper>
  );
};

export default ChangeFolderModal;
