import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Circle,
  Text,
  MenuItem,
  useColorModeValue,
  useToast
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/pro-regular-svg-icons';

const CopyLinkButton = ({
  variant = 'outline',
  colorScheme = null,
  label,
  url,
  isRound = false,
  inMenu,
  icon = faLink
}) => {
  const toast = useToast();
  const { t } = useTranslation();
  const handleOnClickCopy = () => {
    navigator.clipboard.writeText(url);
    toast({
      title: t('toast.link_copied'),
      status: 'success'
    });
  };
  const shareLinkBackground = useColorModeValue('gray.100', 'whiteAlpha.300');
  return inMenu ? (
    <MenuItem
      icon={<FontAwesomeIcon icon={icon} />}
      onClick={handleOnClickCopy}
    >
      <Text>{label ? label : t('button.copy_link')}</Text>
    </MenuItem>
  ) : isRound ? (
    <Button
      variant="ghost"
      justifyContent="flex-start"
      leftIcon={
        <Circle
          fontSize="sm"
          cursor="pointer"
          bg={shareLinkBackground}
          size="24px"
        >
          <FontAwesomeIcon icon={icon} />
        </Circle>
      }
      onClick={handleOnClickCopy}
    >
      <Text>{label ? label : t('button.copy_link')}</Text>
    </Button>
  ) : (
    <Button
      leftIcon={<FontAwesomeIcon icon={icon} />}
      variant={variant}
      colorScheme={colorScheme}
      fontSize="sm"
      onClick={handleOnClickCopy}
    >
      <Text>{label ? label : t('common.share')}</Text>
    </Button>
  );
};

export default CopyLinkButton;
