import axios from 'axios';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Button, Input, MenuItem, Progress, useToast } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import ModalWrapper from 'components/ModalWrapper';
import api from 'utils/api';
import { sleepMs } from 'utils/network';
import { removeFileExtension } from 'utils/file';

const AttachmentUploadComponent = ({
  modelConfig,
  asButton = false,
  onAttachmentUploaded = _ => {}
}) => {
  const attachmentRef = useRef();

  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  const { t } = useTranslation();

  const toast = useToast();

  const queryClient = useQueryClient();

  //move to attachmentUtils
  const handleOnUploadAttachment = async file => {
    if (file.size > 1000000000) {
      toast({
        title: t('toast.file_too_large'),
        status: 'error'
      });
    } else {
      setProgress(0);
      setUploading(true);
      /*
        Perhaps a bit subjective but I feel like the user experience is a bit improved
        by adding a little bit of sleep before and after upload.
      */
      await sleepMs(250);
      try {
        const { data } = await api.post('/signed-url', {
          filename: file.name,
          model_type: modelConfig.singular,
          model_id: modelConfig.modelId
        });

        console.log(data);

        await axios.put(data.signed_url, file, {
          headers: {
            'Content-Type': file.type || 'application/octet-stream' // Ensure this matches the signed URL
          },
          onUploadProgress: ({ loaded, total }) => {
            const percentage = Math.floor((loaded * 100) / total);
            setProgress(percentage);
          }
        });
        const { data: attachment } = await api.post(
          `/${modelConfig.plural}/attachments`,
          {
            key: data.key,
            name: removeFileExtension(file.name),
            [modelConfig.singular]: modelConfig.modelId
          }
        );

        const attachments = queryClient.getQueryData([
          'attachments',
          modelConfig.singular,
          modelConfig.modelId
        ]);

        if (attachments) {
          queryClient.setQueryData(
            ['attachments', modelConfig.singular, modelConfig.modelId],
            [...attachments, attachment]
          );
        }
        onAttachmentUploaded(attachment);
      } catch (e) {
        toast({
          title: t('toast.upload_error', {
            entity: t('common.attachment')
          }),
          status: 'error'
        });
        console.error(e);
      }
      await sleepMs(250);
      setUploading(false);
    }
  };

  return (
    <>
      {asButton ? (
        <Button
          size="sm"
          variant="outline"
          justifyContent="flex-start"
          onClick={() => {
            if (attachmentRef?.current) {
              attachmentRef.current.click();
            }
          }}
        >
          {t('button.add_attachment')}
        </Button>
      ) : (
        <MenuItem
          icon={<FontAwesomeIcon icon={faPaperclip} fixedWidth />}
          onClick={() => {
            if (attachmentRef?.current) {
              attachmentRef.current.click();
            }
          }}
        >
          {t('button.add_attachment')}
        </MenuItem>
      )}
      <Input
        ref={attachmentRef}
        type="file"
        display="none"
        onChange={event => {
          handleOnUploadAttachment(event.target.files[0]);
        }}
      />
      <ModalWrapper
        title={t('common.uploading_attachment')}
        isOpen={uploading}
        pb={6}
      >
        <Progress colorScheme="teal" hasStripe value={progress} rounded="lg" />
      </ModalWrapper>
    </>
  );
};

export default AttachmentUploadComponent;
