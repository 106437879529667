import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import BackButton from 'components/BackButton';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import ProjectTemplateForm from './ProjectTemplateForm';
import ProjectTemplateListItem from './ProjectTemplateListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import api from 'utils/api';

const ProjectTemplateList = () => {
  const {
    isOpen: showCreateTemplateModal,
    onOpen: openCreateTemplateModal,
    onClose: closeCreateTemplateModal
  } = useDisclosure();

  const navigate = useNavigate();
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug]);
  const toast = useToast();

  const { t } = useTranslation();

  const {
    status,
    data: templates,
    isSuccess
  } = useQuery('project-templates', async () => {
    const { data } = await api.get('/project-templates');
    return data;
  });

  const createProjectTemplateMutation = useMutation(
    template => api.post('/project-templates', template),
    {
      onSuccess: ({ data: template }) => {
        closeCreateTemplateModal();
        navigate(`/${slug}/project/template/${template.id}`);
        toast({
          title: t('toast.create_success', {
            entity: t('common.project_template')
          }),
          status: 'success'
        });
      },
      onError: () => {
        toast({
          title: t('toast.create_error', {
            entity: t('common.project_template')
          }),
          status: 'error'
        });
      }
    }
  );

  const handleOnCreateProjectTemplate = async template => {
    await createProjectTemplateMutation.mutateAsync({
      ...template,
      hive: hive.id
    });
  };

  return (
    <Container maxW="container.lg" marginY={8}>
      <Stack spacing={4}>
        <Flex alignItems="center" justifyContent="space-between">
          <Heading fontSize="2xl">{t('common.project_templates')}</Heading>
          <Button colorScheme="teal" onClick={openCreateTemplateModal}>
            <HStack>
              <FontAwesomeIcon icon={faPlus} />
              <Text>{t('common.template')}</Text>
            </HStack>
          </Button>
        </Flex>
        <BackButton />
        <LoadingWrapper
          statuses={[status]}
          errorMessages={[
            t('common.could_not_fetch_data_please_try_again_later', {
              data: t('common.templates').toLowerCase()
            })
          ]}
        >
          {isSuccess ? (
            <SimpleGrid spacing="20px" columns={[1, 2, 3, 4]}>
              {templates.map(template => (
                <ProjectTemplateListItem
                  key={template.id}
                  template={template}
                />
              ))}
            </SimpleGrid>
          ) : null}
        </LoadingWrapper>
      </Stack>
      <ModalWrapper
        title={t('project.create_project_template')}
        size="full"
        isOpen={showCreateTemplateModal}
        onClose={closeCreateTemplateModal}
      >
        <ProjectTemplateForm
          isOpen={showCreateTemplateModal}
          onSubmit={handleOnCreateProjectTemplate}
        />
      </ModalWrapper>
    </Container>
  );
};

export default ProjectTemplateList;
