import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useColorModeValue,
  useDisclosure,
  useMediaQuery,
  useToast
} from '@chakra-ui/react';
import LoginModal from 'components/modals/LoginModal';
import api from 'utils/api';
import CommentForm from './CommentForm';
import CommentList from './CommentList';

const CommentSection = ({
  cardId,
  commentCount,
  updateCard,
  code,
  isAdminOrAssigneeOrCollectionManager
}) => {
  const [isMobile] = useMediaQuery('(max-width: 62em)');
  const [selectedComment, setSelectedComment] = useState(null);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const modalBackgroundColor = useColorModeValue('white', 'gray.700');
  const { t } = useTranslation();
  const toast = useToast();

  const {
    isOpen: showCommentModal,
    onOpen: openCommentModal,
    onClose: closeCommentModal
  } = useDisclosure();

  const {
    isOpen: showLoginModal,
    onOpen: openLoginModal,
    onClose: closeLoginModal
  } = useDisclosure();

  const { data: me } = useQuery('me', { enabled: false });

  const queryClient = useQueryClient();

  const isReply = selectedComment?.parent ? true : false;

  const getPagesWithCountsAdjusted = pages => {
    return pages.map(page => {
      return {
        ...page,
        count: page.results.length
      };
    });
  };

  const getPagesWithCountsChanged = (pages, pageIndex, change) => {
    return pages.map((page, index) => {
      if (index === pageIndex) {
        return {
          ...page,
          count: page.count + change //negative countChange to decrement
        };
      }
      return page;
    });
  };

  const updateCommentMetrics = (commentId, change) => {
    const comments = queryClient.getQueryData(['comments', cardId]);
    const pages = comments.pages.map(page => ({
      ...page,
      results: page.results.map(c =>
        c.id === commentId
          ? {
              ...c,
              metrics: {
                replies: c.metrics.replies + change
              }
            }
          : c
      )
    }));
    queryClient.setQueryData(['comments', cardId], {
      ...comments,
      pages
    });
  };

  const createCommentMutation = useMutation(
    newComment => api.post('/comments', newComment),
    {
      onSuccess: ({ data: newComment }) => {
        if (isReply) {
          const oldReplies = queryClient.getQueryData([
            'replies',
            newComment.parent
          ]);
          let updatedReplyPages;
          if (oldReplies) {
            //increasing the count of the last page
            updatedReplyPages = getPagesWithCountsChanged(
              oldReplies.pages,
              oldReplies.pages.length - 1,
              1
            );
          } else {
            //no existing replies, so adding a new page of reply data
            updatedReplyPages = [
              {
                count: 1,
                next: null,
                previous: null,
                results: [newComment]
              }
            ];
          }
          //add new reply to last page
          const lastPage = updatedReplyPages[updatedReplyPages.length - 1];
          const newLastPage = {
            ...lastPage,
            results: [...lastPage.results, newComment]
          };
          updatedReplyPages[updatedReplyPages.length - 1] = newLastPage;

          queryClient.setQueryData(['replies', newComment.parent], {
            ...oldReplies,
            pages: updatedReplyPages
          });
          //update parent's metrics
          updateCommentMetrics(newComment.parent, true);
          setShouldRefetch(true);
        } else {
          //it's a new comment, not a reply
          const oldComments = queryClient.getQueryData(['comments', cardId]);

          const updatedCommentPages = getPagesWithCountsChanged(
            oldComments.pages,
            oldComments.pages.length - 1,
            1
          );

          if (updatedCommentPages.length > 0) {
            updatedCommentPages[0].results = [
              newComment,
              ...updatedCommentPages[0].results
            ];
          }
          queryClient.setQueryData(['comments', cardId], {
            ...oldComments,
            pages: updatedCommentPages
          });
        }
        const card = queryClient.getQueryData(['card', cardId]);
        updateCard({
          ...card,
          metrics: {
            ...card.metrics,
            comments: card.metrics.comments + 1
          }
        });
        setShouldRefetch(true);
      }
    }
  );

  const deleteCommentMutation = useMutation(
    () => api.delete(`/comments/${selectedComment.id}`),
    {
      onSuccess: () => {
        if (isReply) {
          const oldReplies = queryClient.getQueryData([
            'replies',
            selectedComment.parent
          ]);
          let updatedReplyPages = oldReplies.pages.map(page => ({
            ...page,
            results: page.results.filter(c => c.id !== selectedComment.id)
          }));
          updatedReplyPages = getPagesWithCountsAdjusted(updatedReplyPages);
          queryClient.setQueryData(['replies', selectedComment.parent], {
            ...oldReplies,
            pages: updatedReplyPages
          });
          updateCommentMetrics(selectedComment.parent, -1);
          setShouldRefetch(true);
        } else {
          const oldComments = queryClient.getQueryData(['comments', cardId]);
          let updatedCommentPages = oldComments.pages.map(page => ({
            ...page,
            results: page.results.filter(c => c.id !== selectedComment.id)
          }));
          updatedCommentPages = getPagesWithCountsAdjusted(updatedCommentPages);
          queryClient.setQueryData(['comments', cardId], {
            ...oldComments,
            pages: updatedCommentPages
          });
        }
        const card = queryClient.getQueryData(['card', cardId]);
        updateCard({
          ...card,
          metrics: {
            ...card.metrics,
            comments: card.metrics.comments - 1
          }
        });
        setShouldRefetch(true);
      }
    }
  );

  const updateCommentMutation = useMutation(
    comment => api.patch(`/comments/${selectedComment.id}`, comment),
    {
      onSuccess: ({ data: updatedComment }) => {
        if (isReply) {
          const oldReplies = queryClient.getQueryData([
            'replies',
            selectedComment.parent
          ]);
          const updatedReplyPages = oldReplies.pages.map(page => ({
            ...page,
            results: page.results.map(c =>
              c.id === selectedComment.id ? updatedComment : c
            )
          }));
          queryClient.setQueryData(['replies', selectedComment.parent], {
            ...oldReplies,
            updatedReplyPages
          });
          setShouldRefetch(true);
        } else {
          const comments = queryClient.getQueryData(['comments', cardId]);
          const pages = comments.pages.map(page => ({
            ...page,
            results: page.results.map(c =>
              c.id === selectedComment.id ? updatedComment : c
            )
          }));
          queryClient.setQueryData(['comments', cardId], {
            ...comments,
            pages
          });
        }
        setShouldRefetch(true);
      }
    }
  );

  const handleOnCreateComment = async (comment, _) => {
    if (me) {
      try {
        await createCommentMutation.mutateAsync({ ...comment, card: cardId });
        toast({
          title: t('toast.add_success', {
            entity: t('common.comment')
          }),
          status: 'success'
        });
      } catch {
        toast({
          title: t('toast.add_error', {
            entity: t('common.comment')
          }),
          status: 'error'
        });
      }
    } else if (code) {
      openLoginModal();
    }
  };

  const handleOnCreateReply = async ({ value: comment }, closeModal) => {
    if (me && selectedComment) {
      try {
        await createCommentMutation.mutateAsync({
          comment,
          card: cardId,
          parent: selectedComment.id
        });
        closeModal();
        toast({
          title: t('toast.add_success', {
            entity: t('common.reply_noun')
          }),
          status: 'success'
        });
      } catch {
        toast({
          title: t('toast.add_error', {
            entity: t('common.reply_noun')
          }),
          status: 'error'
        });
      }
    } else if (code) {
      openLoginModal();
    }
  };

  const handleOnDeleteComment = async (comment, closeModal) => {
    setSelectedComment(comment);
    try {
      await deleteCommentMutation.mutateAsync();
      closeModal();
      toast({
        title: t('toast.delete_success', {
          entity: t('common.comment')
        }),
        status: 'success'
      });
    } catch {
      toast({
        title: t('toast.delete_error', {
          entity: t('common.comment')
        }),
        status: 'error'
      });
    }
  };

  const handleOnUpdateComment = async (form, closeModal) => {
    try {
      await updateCommentMutation.mutateAsync({
        comment: form.value
      });
      closeModal();
      toast({
        title: t('toast.update_success', {
          entity: t('common.comment')
        }),
        status: 'success'
      });
    } catch {
      toast({
        title: t('toast.update_error', {
          entity: t('common.comment')
        }),
        status: 'error'
      });
    }
  };

  const initialCommentLimit = isMobile ? 3 : null;

  return (
    <Stack spacing={8} pb={8}>
      <CommentForm
        onSubmit={handleOnCreateComment}
        onFocusCallback={event => {
          if (!me && code) {
            openLoginModal();
            event.target.blur();
          }
        }}
      />
      <CommentList
        limit={initialCommentLimit}
        cardId={cardId}
        code={code}
        onCreateReply={handleOnCreateReply}
        onDeleteComment={handleOnDeleteComment}
        onUpdateComment={handleOnUpdateComment}
        handleOnSetSelectedComment={comment => setSelectedComment(comment)}
        selectedComment={selectedComment}
        isAdminOrAssigneeOrCollectionManager={
          isAdminOrAssigneeOrCollectionManager
        }
        shouldRefetch={shouldRefetch}
        setShouldRefetch={setShouldRefetch}
        updateCard={updateCard}
        openLoginModal={openLoginModal}
        triggerLoginModal={!me && code}
      />
      {initialCommentLimit && commentCount > initialCommentLimit && (
        <Button variant="link" onClick={openCommentModal}>
          {t('button.view_all_nr_comments', { nr: commentCount })}
        </Button>
      )}
      <Modal
        size="full"
        onClose={closeCommentModal}
        isOpen={showCommentModal}
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <Stack
            position="sticky"
            py={2}
            top={0}
            bg={modalBackgroundColor}
            zIndex={9999}
          >
            <ModalCloseButton />
            <ModalHeader>
              {`${commentCount} ${t('common.comments')}`}
            </ModalHeader>
          </Stack>
          <ModalBody py={8}>
            <CommentList
              cardId={cardId}
              code={code}
              onCreateReply={handleOnCreateReply}
              onDeleteComment={handleOnDeleteComment}
              onUpdateComment={handleOnUpdateComment}
              handleOnSetSelectedComment={comment =>
                setSelectedComment(comment)
              }
              selectedComment={selectedComment}
              isAdminOrAssigneeOrCollectionManager={
                isAdminOrAssigneeOrCollectionManager
              }
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <LoginModal isOpen={showLoginModal} onClose={closeLoginModal} />
    </Stack>
  );
};

export default CommentSection;
