import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Button,
  Flex,
  HStack,
  Stack,
  Table,
  Thead,
  Tbody,
  Td,
  Tr,
  Th,
  Text,
  useToast,
  ButtonGroup
} from '@chakra-ui/react';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import ProjectStepForm from './ProjectStepForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashAlt,
  faPlus,
  faArrowDown,
  faArrowUp
} from '@fortawesome/pro-regular-svg-icons';
import api from 'utils/api';

const ProjectStepTable = ({ projectTemplateId }) => {
  const [selectedStep, setSelectedStep] = useState();
  const [showCreateStepModal, setShowCreateStepModal] = useState();
  const [showDeleteStepModal, setShowDeleteStepModal] = useState();
  const [showUpdateStepModal, setShowUpdateStepModal] = useState();

  const toast = useToast();
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const {
    status,
    data: steps,
    isSuccess,
    refetch
  } = useQuery(['project-steps', projectTemplateId], async () => {
    const { data } = await api.get(
      `/project-steps?template=${projectTemplateId}`
    );
    return data;
  });

  const createStepMutation = useMutation(
    step => api.post('/project-steps', step),
    {
      onSuccess: ({ data: step }) => {
        toggleShowCreateStepModal();
        toast({
          title: t('toast.create_success', {
            entity: t('common.step')
          }),
          status: 'success'
        });
        queryClient.setQueryData(
          ['project-steps', projectTemplateId],
          [...steps, step]
        );
      },
      onError: () => {
        toast({
          title: t('toast.create_error', {
            entity: t('common.step')
          }),
          status: 'error'
        });
      }
    }
  );

  const deleteStepMutation = useMutation(
    stepId => api.delete(`/project-steps/${stepId}`),
    {
      onSuccess: () => {
        toggleShowDeleteStepModal();
        queryClient.setQueryData(
          ['project-steps', projectTemplateId],
          steps.filter(s => s.id !== selectedStep.id)
        );
        toast({
          title: t('toast.delete_success', {
            entity: t('common.step')
          }),
          status: 'success'
        });
      }
    }
  );

  const updateStepMutation = useMutation(
    step => api.patch(`/project-steps/${selectedStep.id}`, step),
    {
      onSuccess: ({ data: step }) => {
        toggleShowUpdateStepModal();
        toast({
          title: t('toast.update_success', {
            entity: t('common.step')
          }),
          status: 'success'
        });
        queryClient.setQueryData(
          ['project-steps', projectTemplateId],
          steps.map(s => (s.id === step.id ? step : s))
        );
      },
      onError: () => {
        toast({
          title: t('toast.update_error', {
            entity: t('common.step')
          }),
          status: 'error'
        });
      }
    }
  );

  const moveDownMutation = useMutation(
    stepId => api.patch(`/project-steps/${stepId}/down`),
    {
      onSuccess: () => {
        refetch();
      }
    }
  );

  const moveUpMutation = useMutation(
    stepId => api.patch(`/project-steps/${stepId}/up`),
    {
      onSuccess: () => {
        refetch();
      }
    }
  );

  const toggleShowCreateStepModal = () => {
    setShowCreateStepModal(!showCreateStepModal);
  };

  const toggleShowDeleteStepModal = () => {
    setShowDeleteStepModal(!showDeleteStepModal);
  };

  const toggleShowUpdateStepModal = () => {
    setShowUpdateStepModal(!showUpdateStepModal);
  };

  const handleOnClickDelete = async () => {
    await deleteStepMutation.mutateAsync(selectedStep.id);
  };

  const handleOnCreateStep = async step => {
    await createStepMutation.mutateAsync({
      ...step,
      template: projectTemplateId
    });
  };

  const handleOnUpdateStep = async step => {
    await updateStepMutation.mutateAsync({
      ...step,
      template: projectTemplateId
    });
  };

  const handleOnClickMoveDown = async stepId => {
    await moveDownMutation.mutateAsync(stepId);
  };

  const handleOnClickMoveUp = async stepId => {
    await moveUpMutation.mutateAsync(stepId);
  };

  return (
    <LoadingWrapper
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.steps').toLowerCase()
        })
      ]}
    >
      {isSuccess ? (
        <Stack spacing={4}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>{t('common.step')}</Th>
                <Th textAlign="center">{t('common.priority')}</Th>
                <Th textAlign="end">{t('common.delete')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {steps.map((step, index, { length }) => (
                <Tr key={step.id}>
                  <Td>
                    <Button
                      variant="link"
                      onClick={() => {
                        setSelectedStep(step);
                        toggleShowUpdateStepModal();
                      }}
                    >
                      {step.title}
                    </Button>
                  </Td>
                  <Td textAlign="center">
                    <ButtonGroup>
                      <Button
                        variant="outline"
                        isDisabled={index === 0}
                        onClick={() => handleOnClickMoveUp(step.id)}
                      >
                        <FontAwesomeIcon icon={faArrowUp} />
                      </Button>
                      <Button
                        variant="outline"
                        isDisabled={index === length - 1}
                        onClick={() => handleOnClickMoveDown(step.id)}
                      >
                        <FontAwesomeIcon icon={faArrowDown} />
                      </Button>
                    </ButtonGroup>
                  </Td>
                  <Td textAlign="end">
                    <Button
                      variant="outline"
                      onClick={() => {
                        setSelectedStep(step);
                        toggleShowDeleteStepModal();
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Flex justifyContent="flex-end">
            <Button colorScheme="teal" onClick={toggleShowCreateStepModal}>
              <HStack>
                <FontAwesomeIcon icon={faPlus} />
                <Text>{t('button.create')}</Text>
              </HStack>
            </Button>
          </Flex>
          <ModalWrapper
            title={t('project.create_step')}
            size="full"
            isOpen={showCreateStepModal}
            onClose={toggleShowCreateStepModal}
          >
            <ProjectStepForm
              isOpen={showCreateStepModal}
              onSubmit={handleOnCreateStep}
            />
          </ModalWrapper>
          <ModalWrapper
            title={t('common.step')}
            isOpen={showUpdateStepModal}
            onClose={toggleShowUpdateStepModal}
          >
            <ProjectStepForm
              defaultValues={{
                title: selectedStep?.title,
                description: selectedStep?.description
              }}
              isOpen={showUpdateStepModal}
              onSubmit={handleOnUpdateStep}
            />
          </ModalWrapper>
          <ConfirmationModal
            deleteText={t(
              'confirmation.this_information_will_be_permanently_removed_and_cannot_be_restored'
            )}
            isOpen={showDeleteStepModal}
            onClose={toggleShowDeleteStepModal}
            onDelete={handleOnClickDelete}
          />
        </Stack>
      ) : null}
    </LoadingWrapper>
  );
};

export default ProjectStepTable;
