import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, useDisclosure, useToast } from '@chakra-ui/react';
import api from 'utils/api';
import DesktopCreateButton from './DesktopCreateButton';
import InviteModal from 'features/hive/InviteModal';
import CollectionCreationForm from 'features/collection/CollectionCreationForm';
import ModalWrapper from './ModalWrapper';
import AnnouncementForm from 'features/announcement/AnnouncementForm';
import CardCreationForm from 'features/card/CardCreationForm';
import ProjectForm from 'features/project/ProjectForm';
import MobileCreateButton from './MobileCreateButton';
import { hasPlanGreaterThanOrEqual, Plans } from 'features/hive/hiveUtils';

const CreateButton = () => {
  const { slug } = useParams();
  const { data: me } = useQuery('me');
  const { data: hive } = useQuery(['hive', slug]);

  const [newCollection, setNewCollection] = useState(null);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const toast = useToast();

  const { t } = useTranslation();

  const {
    isOpen: showCreateCardModal,
    onOpen: openCreateCardModal,
    onClose: closeCreateCardModal
  } = useDisclosure();

  const {
    isOpen: showCreateCollectionModal,
    onOpen: openCreateCollectionModal,
    onClose: closeCreateCollectionModal
  } = useDisclosure();

  const {
    isOpen: showCreateProjectModal,
    onOpen: openCreateProjectModal,
    onClose: closeCreateProjectModal
  } = useDisclosure();

  const {
    isOpen: showInviteModal,
    onOpen: openInviteModal,
    onClose: closeInviteModal
  } = useDisclosure();

  const {
    isOpen: showCreateAnnouncementModal,
    onOpen: openCreateAnnouncementModal,
    onClose: closeCreateAnnouncementModal
  } = useDisclosure();

  const createAnnouncementMutation = useMutation(
    async announcement =>
      await api.post('/announcements', announcement, { timeout: 0 }),
    {
      onSuccess: ({ data: announcement }) => {
        const announcements = queryClient.getQueryData('announcements');
        if (announcements && announcements.pages) {
          const pages = announcements.pages.map(page => {
            return {
              ...page,
              count: page.count + 1
            };
          });
          if (pages.length > 0) {
            pages[0].results = [announcement, ...pages[0].results];
          }
          queryClient.setQueryData('announcements', {
            ...announcements,
            pages
          });
        }
        const home = queryClient.getQueryData('home');
        if (home) {
          queryClient.setQueryData('home', {
            ...home,
            announcement
          });
        }
        closeCreateAnnouncementModal();
        toast({
          title: t('toast.create_success', {
            entity: t('common.announcement')
          }),
          status: 'success'
        });
      }
    }
  );

  const createInvitesMutation = useMutation(
    async emails => await api.post('/hives/invite', emails),
    {
      onSuccess: () => {
        toast({
          title: t('toast.send_success', {
            entity: t('common.invites_noun_plural')
          }),
          status: 'success'
        });
        closeInviteModal();
      },
      onError: () => {
        toast({
          title: t('toast.send_error', {
            entity: t('common.invites_noun_plural')
          }),
          status: 'error'
        });
      }
    }
  );

  const createProjectMutation = useMutation(
    project => api.post('/projects', project),
    {
      onSuccess: ({ data: project }) => {
        closeCreateProjectModal();
        toast({
          title: t('toast.create_success', {
            entity: t('common.project')
          }),
          status: 'success'
        });
        navigate(`/${slug}/project/${project.id}`);
      }
    }
  );

  const handleOnCreateAnnouncement = async announcement => {
    const payload = new FormData();
    payload.append('hive', hive.id);
    payload.append('image', announcement.image ? announcement.image : '');
    payload.append(
      'unsplash',
      announcement.unsplash ? announcement.unsplash : ''
    );
    payload.append('video', announcement.video ? announcement.video : '');
    payload.append('title', announcement.title);
    payload.append(
      'description',
      announcement.description ? announcement.description : ''
    );
    await createAnnouncementMutation.mutateAsync(payload);
  };

  const handleOnCreateInvites = async values => {
    const emails = values.emails
      .map(email => email.value)
      .filter(e => /\S+@\S+\.\S+/.test(e));
    if (emails.length > 0) {
      await createInvitesMutation.mutateAsync({
        hive: hive.id,
        emails,
        subject: values.subject,
        message: values.message
      });
    }
  };

  const handleOnCreateProject = async project => {
    await createProjectMutation.mutateAsync({
      ...project,
      active: true,
      channel: project.channel.id,
      template: project.template ? project.template.id : null
    });
  };

  const handleOnCloseCreateCollectionModal = () => {
    closeCreateCollectionModal();
    if (newCollection) {
      //always false cuz hook not updated in time
      navigate(`/${slug}/collection/${newCollection.id}`);
    }
  };

  return me ? (
    <>
      <Box display={['none', null, null, 'inline']}>
        <DesktopCreateButton
          openCreateAnnouncementModal={openCreateAnnouncementModal}
          openCreateCardModal={openCreateCardModal}
          openCreateCollectionModal={openCreateCollectionModal}
          openCreateProjectModal={openCreateProjectModal}
          openInviteModal={openInviteModal}
          me={me}
        />
      </Box>
      <Box display={['inline', null, null, 'none']}>
        <MobileCreateButton
          openCreateAnnouncementModal={openCreateAnnouncementModal}
          openCreateCollectionModal={openCreateCollectionModal}
          openCreateProjectModal={openCreateProjectModal}
          openInviteModal={openInviteModal}
          me={me}
        />
      </Box>
      <InviteModal
        me={me}
        isOpen={showInviteModal}
        onClose={closeInviteModal}
        onSubmit={handleOnCreateInvites}
      />
      <ModalWrapper
        size="full"
        isOpen={showCreateCollectionModal}
        onClose={handleOnCloseCreateCollectionModal}
        hasSteps
      >
        <CollectionCreationForm
          isOpen={showCreateCollectionModal}
          onClose={handleOnCloseCreateCollectionModal}
          onCollectionCreated={newCollection => {
            setNewCollection(newCollection);
          }}
          newCollection={newCollection}
        />
      </ModalWrapper>
      {hasPlanGreaterThanOrEqual(hive?.plan, Plans.Professional) && (
        <ModalWrapper
          title={t('project.create_project')}
          size="full"
          isOpen={showCreateProjectModal}
          onClose={closeCreateProjectModal}
        >
          <ProjectForm
            isOpen={showCreateProjectModal}
            onSubmit={handleOnCreateProject}
          />
        </ModalWrapper>
      )}
      <ModalWrapper
        title={t('home.create_announcement')}
        size="full"
        isOpen={showCreateAnnouncementModal}
        onClose={closeCreateAnnouncementModal}
      >
        <AnnouncementForm
          isOpen={showCreateAnnouncementModal}
          onSubmit={handleOnCreateAnnouncement}
        />
      </ModalWrapper>
      <ModalWrapper
        size="full"
        isOpen={showCreateCardModal}
        onClose={closeCreateCardModal}
        hasSteps
      >
        <CardCreationForm
          isOpen={showCreateCardModal}
          onClose={closeCreateCardModal}
        />
      </ModalWrapper>
    </>
  ) : null;
};

export default CreateButton;
