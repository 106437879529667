import { AsyncSelect, components } from 'chakra-react-select';
import i18next from 'i18next';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Heading,
  HStack,
  Stack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  FormControl,
  FormHelperText,
  Divider,
  Button
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faUserGroup } from '@fortawesome/pro-regular-svg-icons';
import ModalWrapper from 'components/ModalWrapper';
import MemberOptionLabel from 'features/member/MemberOptionLabel';
import api from 'utils/api';
import { useQuery } from 'react-query';
import { Roles, hasRoleGreaterThanOrEqual } from 'features/member/memberUtils';
import { Controller, useForm } from 'react-hook-form';
import { useUi } from 'providers/UiProvider';

const fetchUsers = async (query, slug) => {
  const { data } = await api.get(
    `/users?search=${query}&profiles__hive__slug=${slug}`
  );
  return data;
};

const MembersModal = ({
  code,
  title = i18next.t('common.members'),
  body,
  extra = null,
  members,
  isOpen,
  onClose,
  onSubmit = () => {},
  canEdit,
  canRemoveSelf = true
}) => {
  const { slug } = useParams();
  const { t } = useTranslation();
  const { data: me } = useQuery('me', { enabled: false });
  const { data: profile } = useQuery(['profile', slug], { enabled: false });
  const { setModalClosureLocked } = useUi();

  const canRemove = id =>
    canRemoveSelf ||
    hasRoleGreaterThanOrEqual(profile?.role, Roles.MANAGER) ||
    id !== me?.id;

  const MultiValueRemove = props => {
    if (!canRemove(props.data.id)) {
      return null;
    }
    return <components.MultiValueRemove {...props} />;
  };

  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { isSubmitting, isSubmitSuccessful, isValid, isDirty }
  } = useForm({
    mode: 'onChange',
    defaultValues: { selected_members: members }
  });

  const value = watch('selected_members', { selected_members: members });

  useEffect(() => {
    if (isSubmitSuccessful) {
      onClose();
      reset();
    }
  }, [onClose, reset, isSubmitSuccessful]);

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen, reset, members]);

  const CurrentMembersList = () => (
    <Stack
      opacity={isDirty ? 0.5 : 1.0}
      spacing={4}
      maxHeight="400px"
      overflowY="auto"
    >
      {members?.map((member, index) => (
        <HStack
          key={`${member?.id}_${index}`}
          as={code || isDirty ? null : Link}
          to={isDirty ? null : `/${slug}/member/${member?.id}`}
          maxWidth="fit-content"
          spacing={4}
          cursor={isDirty ? 'not-allowed' : null}
        >
          <Avatar
            size="sm"
            src={
              member?.avatar
                ? member.avatar
                : member?.third_party_avatar
                ? member.third_party_avatar
                : null
            }
            name={member?.full_name}
          />
          <Heading fontSize="sm" whiteSpace="break-spaces">
            {member.full_name}
          </Heading>
        </HStack>
      ))}
    </Stack>
  );

  useEffect(() => {
    if (me) {
      reset();
    }
  }, [members, me, reset]);

  useEffect(() => {
    setModalClosureLocked(isDirty);
  }, [isDirty, setModalClosureLocked]);

  const orderMembers = members => {
    return members
      .filter(m => !canRemove(m.id))
      .concat(members.filter(m => canRemove(m.id)));
  };

  const onChangeMembers = (newMembers, actionMeta) => {
    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
        if (actionMeta.removedValue.isFixed) {
          return;
        }
        break;
      case 'clear':
        newMembers = members.filter(v => !canRemove(v.id));
        break;
      default:
        break;
    }
    return orderMembers(newMembers);
  };

  return (
    <ModalWrapper
      headerContent={
        <HStack>
          <Text as="span">{title}</Text>
          <Text variant="muted" fontSize="md">{`(${members.length})`}</Text>
        </HStack>
      }
      isOpen={isOpen}
      onClose={onClose}
      confirmClosure={isDirty}
    >
      <Stack spacing={4}>
        {body && (
          <FormControl>
            <FormHelperText whiteSpace="pre-line">{body}</FormHelperText>
          </FormControl>
        )}
        {canEdit ? (
          <form
            onSubmit={handleSubmit(form => {
              onSubmit({ selected_members: form.selected_members });
            })}
          >
            <Tabs size="sm" variant="fresh">
              <TabList>
                <Tab isDisabled={isDirty}>
                  <HStack>
                    <FontAwesomeIcon icon={faUserGroup} />
                    <Text>{title}</Text>
                  </HStack>
                </Tab>
                <Tab>
                  <HStack>
                    <FontAwesomeIcon icon={faEdit} />
                    <Text>{t('common.edit')}</Text>
                  </HStack>
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  {members.length > 0 ? (
                    <CurrentMembersList />
                  ) : (
                    <Text variant="muted" textAlign="center">
                      {t('common.no_one_has_been_added_yet')}
                    </Text>
                  )}
                </TabPanel>
                <TabPanel>
                  <Stack spacing={8}>
                    <FormControl>
                      <Controller
                        control={control}
                        name="selected_members"
                        defaultValue={members}
                        rules={{
                          validate: newMembers => {
                            if (value?.find(m => m.id === me?.id)) {
                              if (
                                !newMembers.find(m => m.id === me?.id) &&
                                !canRemove(me?.id)
                              ) {
                                return t(
                                  'validation.you_cannot_remove_yourself'
                                );
                              }
                            }
                          }
                        }}
                        render={({ field: { onChange, ...field } }) => (
                          <AsyncSelect
                            {...field}
                            zIndex={2}
                            isMulti
                            loadOptions={query => fetchUsers(query, slug)}
                            placeholder={t('placeholder.search')}
                            getOptionLabel={member => (
                              <MemberOptionLabel member={member} />
                            )}
                            getOptionValue={member => member.id}
                            onChange={(newMembers, actionMeta) => {
                              const adjustedMembers = onChangeMembers(
                                newMembers,
                                actionMeta
                              );
                              onChange(adjustedMembers);
                            }}
                            isClearable={value?.some(v => canRemove(v.id))}
                            useBasicStyles
                            components={{ MultiValueRemove }}
                          />
                        )}
                      />
                    </FormControl>
                    <HStack justifyContent="flex-end" spacing={2}>
                      <Button
                        variant="outline"
                        onClick={() => reset()}
                        isDisabled={!isDirty}
                      >
                        {t('button.cancel')}
                      </Button>
                      <Button
                        isDisabled={!isDirty || !isValid}
                        isLoading={isSubmitting}
                        colorScheme="teal"
                        type="submit"
                        width="fit-content"
                      >
                        {t('button.save')}
                      </Button>
                    </HStack>
                  </Stack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </form>
        ) : members.length > 0 ? (
          <CurrentMembersList />
        ) : (
          <Text variant="muted" textAlign="center">
            {t('common.no_one_has_been_added_yet')}
          </Text>
        )}
        {extra && (
          <>
            <Divider />
            {extra}
          </>
        )}
      </Stack>
    </ModalWrapper>
  );
};

export default MembersModal;
